import axios from 'axios'
import config from './config'

let BASE_URL = null
// eslint-disable-next-line
if (process.env.NODE_ENV === 'development') {
  BASE_URL = config.BASE_URL
} else {
  BASE_URL = config.PROD_URL
}

const incrementUserMessageCount = (username, setMessageCount) => {
  axios.get(`${BASE_URL}/increment_user_message_count?username=${username}`).then((response) => {
    setMessageCount(response.data.user_message_count)
  })
}

const syncHistory = async (oldUsername, newUsername, tone) => {
  await axios.post(`${BASE_URL}/sync_history`, { oldUsername, newUsername, tone })
}

const putPaidBit = async (username, sessionId) => {
  await axios.post(`${BASE_URL}/set_user_payment_information`, { username, sessionId })
}

const getPaidBit = async (username) => {
  const response = await axios.post(`${BASE_URL}/get_user_payment_information`, { username })
  return response.data.paid
}

export { BASE_URL, incrementUserMessageCount, syncHistory, putPaidBit, getPaidBit }
