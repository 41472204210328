import React from 'react'

import Chat from './ChatRoom/Chat'
import ToneConfig from './ChatRoom/ToneConfig'
import logo from './images/pillowtalks-chat-small-logo.png'
import SocialFollow from './SocialFollow'
import TermsOfService from './TermsOfService'
import { useState } from 'react'
import SignOutButton from './SignOutButton'
import PropTypes from 'prop-types'
import { BuyTones, ManageSubscription } from './StripeComponents'
import { useRecoilValue } from 'recoil'
import { usernameState, isRolePlayingState } from './atoms'
import RoleplayForm from './ChatRoom/RoleplayForm'
import RoleplaySwitch from './ChatRoom/RoleplaySwitch'
import LandingPageButton from './LandingPageButton'

function ChatRoom({ signOut, user, paid }) {
  const username = useRecoilValue(usernameState)
  const isRolePlaying = useRecoilValue(isRolePlayingState)
  const TermsOfUse = () => {
    const [showTerms, setShowTerms] = useState(false)

    const handleTermsClick = () => {
      setShowTerms(true)
    }

    const handleCloseTerms = () => {
      setShowTerms(false)
    }

    return (
      <div className='text-xs inline-block text-blue-600 hover:text-black cursor-pointer'>
        <button onClick={handleTermsClick}>Terms of Use</button>
        {showTerms && (
          <div className='absolute top-0 left-0'>
            <div className='overflow-scroll overflow-x-hidden flex justify-center fixed top-0 w-full h-full bg-black bg-opacity-40 z-999'>
              <div className='w-3/4 bg-white text-black rounded-lg mx-1 my-auto p-8'>
                <TermsOfService />
                <div
                  onClick={handleCloseTerms}
                  className='text-red-500 py-4 hover:text-white hover:bg-red-500 hover:bg-none line-height-60 cursor-pointer sticky bottom-0 mx-auto text-center h-70 rounded-md font-bold leading-50 bg-gradient-to-t from-white via-white to-white/69'
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  const showPremium = !user || paid
  const showBuyPremium = user && !paid
  const showManage = user && paid
  return (
    <div id='container' className='w-full h-5/6 md:h-fit md:grid md:grid-cols-4'>
      <div
        id='nav-config'
        className='col-span-1 grid grid-rows-6 invisible md:visible h-0 md:h-screen overflow-auto'
      >
        <div id='config' className='p-4 flex flex-col invisible md:visible'>
          <img src={logo} />
          <div className='border mt-2 mb-4'></div>
          <RoleplaySwitch showPremium={showPremium} />
          <ToneConfig showPremium={showPremium} shouldShow={!isRolePlaying} />
          <RoleplayForm shouldShow={isRolePlaying} />
          <BuyTones shouldShow={showBuyPremium} username={username} />
          <ManageSubscription shouldShow={showManage} username={username} />
          <SignOutButton signOut={signOut} />
          <LandingPageButton />
        </div>
        <div
          id='social-config'
          className='row-start-6 col-span-1 border-t h-full p-4 bg-gray-50 border-gray-200'
        >
          <SocialFollow />
          <TermsOfUse />
          <div className='text-xs inline-block'>
            © 2023 PillowTalks.AI, LLC - All Rights Reserved.
          </div>
        </div>
      </div>

      <div id='chat' className='md:col-span-3 h-screen'>
        <Chat />
        <div id='mobile-tone-config' className='visible md:hidden'>
          <RoleplaySwitch showPremium={showPremium} />
          <ToneConfig showPremium={showPremium} shouldShow={!isRolePlaying} />
          <RoleplayForm shouldShow={isRolePlaying} />
          <BuyTones shouldShow={showBuyPremium} username={username} />
          <ManageSubscription shouldShow={showManage} username={username} />
          <SignOutButton signOut={signOut} />
          <LandingPageButton />
        </div>
      </div>
    </div>
  )
}

ChatRoom.propTypes = {
  signOut: PropTypes.func,
  user: PropTypes.object,
  paid: PropTypes.bool,
}

export default ChatRoom
