import React, { useState } from 'react'

const AgreePrompt = () => {
  const [readMoreSelected, setReadMoreSelected] = useState(false)
  const [isVisible, setIsVisible] = useState(true)

  const handleReadMoreClick = () => {
    setReadMoreSelected(!readMoreSelected)
  }

  const handleWhy18Click = () => {
    const why18 = document.getElementById('why18')
    why18.textContent =
      '(Since explicit content may be generated as a result of engagements with the Pillow AI chatbot, all Pillow AI chatbot users must be at least 18 years old.)'
  }

  const handleAgreeClick = () => {
    setIsVisible(false)
  }

  return (
    <>
      {isVisible && (
        <div className='flex justify-center overflow-scroll bg-black bg-opacity-40 fixed top-0 h-screen w-full z-50 absolute left-0'>
          <div className='bg-white text-black rounded-md md:w-1/2 md:max-w-x1 md:h-fit mx-4 my-8 py-8 px-12 overflow-y-auto'>
            <div className='font-bold text-center text-2xl pb-6'>
              Please read and agree to continue.
            </div>
            <div className='text-lg'>
              By clicking &quot;I Agree&quot; below and/or using our chatbot &quot;Pillow&quot;, you
              understand and agree to the following:
              <ul className='list-disc list-outside my-4' id='agreePromptList'>
                <li>
                  Any messages or other content produced by Pillow does not in any way represent
                  statements or positions of PillowTalks.AI
                </li>
                <li>
                  Any messages or other content is intended solely for entertainment purposes, and
                  certain messages or other content may include false information or assertions
                </li>
                <li>
                  <strong>You are at least 18 years of age</strong>{' '}
                  <span
                    id='why18'
                    className='text-gray-500 cursor-pointer inline-block'
                    onClick={handleWhy18Click}
                  >
                    (why 18?)
                  </span>
                </li>
              </ul>
              <p></p>
              <div
                id='readMore'
                className={`text-center text-lg cursor-pointer px-4 py-2 rounded-md my-4 ${
                  readMoreSelected ? 'selected bg-gray-300' : 'text-gray-500'
                }`}
                onClick={handleReadMoreClick}
              >
                {readMoreSelected
                  ? "Rather than simply avoiding circumstances that evoke ethical questions, PillowTalks embraces ethical inquiry as it more often than not leads to ethical growth. It is our hope to grow and learn from users' experiences with AI chatbots, which we hope and believe will provide a variety of benefits. Nonetheless, the Pillow AI chatbot is at an alpha stage and so users may experience strange or off-putting responses in some circumstances. We encourage you to report any such strange, off-putting, or other notable responses to us at info@pillowtalks.chat."
                  : 'read more'}
              </div>
              <div className='text-center font-bold my-4'>
                By continuing below, you agree that PillowTalks.AI will not in any way be held
                responsible for messages or content generated by its AI chatbot.
              </div>
              <div
                id='agreeBtn'
                className='text-2xl font-bold cursor-pointer text-red-500 mt-8 text-center'
                onClick={handleAgreeClick}
              >
                I Agree
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default AgreePrompt
