import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'

function LandingPage() {
  return (
    <div>
      <h1>Welcome to the PillowTalks.chat, an interface for communicating with Pillow Talk AI!</h1>
      <p>
        Pillow Talk AI is similar to chatGPT and allows the user to interact with a NSFW (18+)
        chatbot that can take on multiple different tones or role play. This allows a more
        personalized experience.
      </p>
      <p>Related: Neural network, LLM, Machine Learning (ML), Artificial Intelligence (AI)</p>
      <Button>
        <Link to='/'>Back to Chat</Link>
      </Button>
    </div>
  )
}

export default LandingPage
