import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faReddit } from '@fortawesome/free-brands-svg-icons'

export default function SocialFollow() {
  return (
    <div className='flex gap-x-2'>
      <a href='mailto:hey@pillowtalks.chat' className='email social' target='noblank'>
        <FontAwesomeIcon
          icon={faEnvelope}
          size='2x'
          className='bg-gray-400 p-2 font-semibold text-white inline-flex items-center space-x-2 rounded'
        />
      </a>
      <a href='https://www.twitter.com/PillowTalksAI' className='twitter social' target='noblank'>
        <FontAwesomeIcon
          icon={faTwitter}
          size='2x'
          className='bg-blue-400 p-2 font-semibold text-white inline-flex items-center space-x-2 rounded'
        />
      </a>
      <a href='https://www.reddit.com/r/pillowtalksai' className='reddit social' target='noblank'>
        <FontAwesomeIcon
          icon={faReddit}
          size='2x'
          className='bg-red-500 p-2 font-semibold text-white inline-flex items-center space-x-2 rounded'
        />
      </a>
    </div>
  )
}
