import React, { useEffect } from 'react'
import 'react-chatbot-kit/build/main.css'

import { Amplify } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css'
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'

import awsExports from './aws-exports'

import PropTypes from 'prop-types'
import { RecoilRoot, useRecoilState, useRecoilValue } from 'recoil'
import { v4 as uuidv4 } from 'uuid'
import ChatRoom from './ChatRoom'
import AgreePrompt from './AgreePrompt'
import {
  messageCountState,
  usernameState,
  toneState,
  paidState,
  isRolePlayingState,
  roleplayCharacterState,
} from './atoms'
import { getPaidBit, putPaidBit, syncHistory } from './api'
import { Modal } from '@mui/material'
import Cookies from 'js-cookie'
import { AnalyticsBrowser } from '@segment/analytics-next'
Amplify.configure(awsExports)
const analytics = AnalyticsBrowser.load({ writeKey: 'eorlEYmyboQypDCfbsPoBMfZyV1vROJf' })

function ChatApp() {
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const [username, setUsername] = useRecoilState(usernameState)
  const numMessagesSent = useRecoilValue(messageCountState)
  const tone = useRecoilValue(toneState)
  const [paid, setPaid] = useRecoilState(paidState)
  const [isRolePlaying, setIsRolePlaying] = useRecoilState(isRolePlayingState)
  const roleplayCharacter = useRecoilValue(roleplayCharacterState)

  useEffect(() => {
    if (user) {
      // Check to see if this is a redirect back from Checkout
      const query = new URLSearchParams(window.location.search)

      if (query.get('success')) {
        const sessionId = query.get('session_id')
        putPaidBit(user.username, sessionId).then(async () => {
          getPaidBit(user.username).then((paidBit) => {
            setPaid(paidBit)
          })
        })
      } else {
        getPaidBit(user.username).then((paidBit) => {
          setPaid(paidBit)
        })
      }
    }
  }, [user])

  if (user) {
    if (username !== user.username) {
      // If username from recoil does not equal the username from amplify it means user just authenticated
      if (isRolePlaying) {
        if (!paid) {
          setIsRolePlaying(false)
        }
        syncHistory(username, user.username, roleplayCharacter)
      } else {
        syncHistory(username, user.username, tone)
      }
    }
    analytics.identify(user.username)
    setUsername(user.username)
  } else if (username === null) {
    const username = Cookies.get('username')
    if (username) {
      setUsername(username)
    } else {
      const uuid = uuidv4()
      Cookies.set('username', uuid)
      setUsername(uuid)
    }
  }
  const signinRequired = !(user || numMessagesSent < 5)
  return (
    <Authenticator.Provider>
      <div className='flex flex-col items-center gap-y-4'>
        <Modal open={signinRequired}>
          <Authenticator />
        </Modal>
        <ChatRoom signOut={signOut} user={user} paid={paid} />
        <AgreePrompt />
      </div>
    </Authenticator.Provider>
  )
}

ChatApp.propTypes = {
  signOut: PropTypes.func,
  user: PropTypes.object,
}

function RecoilChatApp({ signOut, user }) {
  return (
    <Authenticator.Provider>
      <RecoilRoot>
        <ChatApp signOut={signOut} user={user} />
      </RecoilRoot>
    </Authenticator.Provider>
  )
}

RecoilChatApp.propTypes = {
  signOut: PropTypes.func,
  user: PropTypes.object,
}

export default RecoilChatApp
