import React from 'react'
import PropTypes from 'prop-types'
import config from './config'

let BASE_URL = null
// eslint-disable-next-line
if (process.env.NODE_ENV === 'development') {
  BASE_URL = config.BASE_URL
} else {
  BASE_URL = config.PROD_URL
}

const checkoutUrl = `${BASE_URL}/create_checkout_session`
const manageUrl = `${BASE_URL}/create_portal_session`

const BuyTones = ({ shouldShow, username }) => {
  if (shouldShow) {
    return (
      <section className='bg-sky-400 bg-opacity-40'>
        <div className='product'>
          <div className='description'>
            <h3>Get access to premium tones</h3>
            <h5>$.99 / month</h5>
          </div>
        </div>
        <form action={checkoutUrl} method='POST'>
          <input type='hidden' name='lookup_key' value='tonesprem' />
          <input type='hidden' name='username' value={username} />
          <button
            className='inline-flex items-center px-6 py-3 text-gray-600 bg-sky-500 rounded-md hover:bg-sky-600 hover:text-gray-700'
            id='checkout-and-portal-button'
            type='submit'
          >
            Unlock now
          </button>
        </form>
      </section>
    )
  }
}

BuyTones.propTypes = {
  shouldShow: PropTypes.bool,
  username: PropTypes.string,
}

const ManageSubscription = ({ username, shouldShow }) => {
  if (shouldShow) {
    return (
      <section>
        <form action={manageUrl} method='POST'>
          <input type='hidden' id='username' name='username' value={username} />
          <button
            className='inline-flex items-center px-6 py-3 text-gray-600 bg-red-300 rounded-md hover:bg-red-400 hover:text-gray-700'
            id='checkout-and-portal-button'
            type='submit'
          >
            Manage your subscription
          </button>
        </form>
      </section>
    )
  }
}

ManageSubscription.propTypes = {
  username: PropTypes.string,
  shouldShow: PropTypes.bool,
}

export { BuyTones, ManageSubscription }
