import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import RecoilChatApp from './ChatApp'

import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import LandingPage from './LandingPage'
Amplify.configure(awsconfig)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/landing' element={<LandingPage />} />
        <Route path='/' element={<RecoilChatApp />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals()
