import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useRecoilState, useRecoilValue } from 'recoil'
import { isRolePlayingState, usernameState } from '../atoms'

function RoleplaySwitch({ showPremium }) {
  const [isRolePlaying, setIsRolePlaying] = useRecoilState(isRolePlayingState)
  const [checked, setChecked] = useState(false)
  const username = useRecoilValue(usernameState)

  const handleChange = (event) => {
    setIsRolePlaying(!isRolePlaying)
    setChecked(event.target.checked)
  }

  useEffect(() => {
    if (!showPremium) {
      setChecked(false)
    }
  }, [username])

  if (showPremium) {
    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              onChange={() => {
                handleChange(event)
              }}
            />
          }
          label='Roleplay'
        />
      </FormGroup>
    )
  } else {
    return (
      <FormGroup>
        <FormControlLabel
          control={<Switch disabled key={checked.toString()} checked={checked} />}
          label='Roleplaying'
        />
      </FormGroup>
    )
  }
}

RoleplaySwitch.propTypes = {
  showPremium: PropTypes.bool,
}

export default RoleplaySwitch
