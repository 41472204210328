import React, { useState, useEffect } from 'react'

const TermsOfService = () => {
  const [htmlContent, setHtmlContent] = useState('')

  useEffect(() => {
    const fetchHtml = async () => {
      const response = await fetch('./terms.html')
      const html = await response.text()
      setHtmlContent(html)
    }

    fetchHtml()
  }, [])

  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
}

export default TermsOfService
