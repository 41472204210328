import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'

function LandingPageButton() {
  return (
    <div>
      <Button>
        <Link to='/landing'>Learn More</Link>
      </Button>
    </div>
  )
}

export default LandingPageButton
