import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormControlLabel, FormLabel, RadioGroup, Radio } from '@mui/material'
import { useSetRecoilState } from 'recoil'
import { toneState } from './atoms'

function ToneControls({ showPremium }) {
  const setTone = useSetRecoilState(toneState)
  return (
    <FormControl
      id='tone-radio-buttons'
      className='justify-center items-center'
      sx={{ display: 'grid' }}
    >
      <FormLabel
        id='tone-radio-buttons-group-label'
        sx={{ fontSize: 24, fontWeight: 'heavy', paddingLeft: '7px' }}
      >
        Chat Tone
      </FormLabel>
      <RadioGroup
        id='tone-radio-group-label'
        aria-labelledby='tone-radio-buttons-group-label'
        defaultValue='cute'
        name='radio-buttons-group'
        row
        className='grid px-3 w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:border-gray-600 dark:black'
      >
        <FormControlLabel
          value='cute'
          control={<Radio />}
          label='Cute'
          onChange={() => {
            setTone('cute')
          }}
        />
        <FormControlLabel
          value='therapeutic'
          control={<Radio />}
          label='Therapeutic'
          onChange={() => {
            setTone('therapeutic')
          }}
        />
        <FormControlLabel
          value='flirty'
          control={showPremium ? <Radio /> : <Radio disabled={true} checked={false} />}
          label='Flirty'
          onChange={() => {
            setTone('flirty')
          }}
        />
        <FormControlLabel
          value='hot'
          control={showPremium ? <Radio /> : <Radio disabled={true} checked={false} />}
          label='Hot'
          onChange={() => {
            setTone('hot')
          }}
        />
      </RadioGroup>
    </FormControl>
  )
}

ToneControls.propTypes = {
  showPremium: PropTypes.bool,
}

export default ToneControls
