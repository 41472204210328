import React from 'react'
import ToneControls from '../ToneControls'
import PropTypes from 'prop-types'

function ToneConfig({ showPremium, shouldShow }) {
  if (shouldShow) {
    return (
      <div>
        <div className='items-center mb-4'>
          <ToneControls showPremium={showPremium} />
        </div>
      </div>
    )
  }
}

ToneConfig.propTypes = {
  showPremium: PropTypes.bool,
  shouldShow: PropTypes.bool,
}

export default ToneConfig
