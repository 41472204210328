import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import { useSetRecoilState } from 'recoil'
import { roleplayCharacterState } from '../atoms'

function RoleplayForm({ shouldShow }) {
  const setRoleplayCharacter = useSetRecoilState(roleplayCharacterState)
  if (shouldShow) {
    return (
      <div>
        <div className='items-center mb-4'>
          <TextField
            id='outlined-basic'
            label='Roleplay Character'
            variant='outlined'
            onChange={(event) => {
              setRoleplayCharacter(event.target.value)
            }}
          />
        </div>
      </div>
    )
  }
}

RoleplayForm.propTypes = {
  shouldShow: PropTypes.bool,
}

export default RoleplayForm
