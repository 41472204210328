import React from 'react'
import PropTypes from 'prop-types'

function SignOutButton({ signOut }) {
  return (
    <div>
      <button
        className='inline-flex items-center px-6 py-3 text-gray-500 bg-gray-100 rounded-md hover:bg-gray-200 hover:text-gray-600'
        onClick={signOut}
      >
        Sign Out
      </button>
    </div>
  )
}

SignOutButton.propTypes = {
  signOut: PropTypes.func,
}

export default SignOutButton
