/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "0c72d43c57ca4063966d94635222e189",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "0c72d43c57ca4063966d94635222e189",
            "region": "us-east-1"
        }
    },
    "aws_cognito_identity_pool_id": "us-east-1:cd4e464f-0fbe-4c50-ae1e-a6430c60b93c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ABBG9Ld1f",
    "aws_user_pools_web_client_id": "32dg25u3jbh497sapus5v08htl",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
