import { atom } from 'recoil'

const usernameState = atom({
  key: 'usernameState',
  default: null,
})

const toneState = atom({
  key: 'toneState',
  default: 'cute',
})

const messageState = atom({
  key: 'messageState',
  default: '',
})

const messagesState = atom({
  key: 'messagesState',
  default: [],
})

const messageCountState = atom({
  key: 'messageCountState',
  default: 0,
})

const paidState = atom({
  key: 'paidState',
  default: false,
})

const isRolePlayingState = atom({
  key: 'isRolePlaying',
  default: false,
})

const roleplayCharacterState = atom({
  key: 'roleplayCharacter',
  default: '',
})

export {
  usernameState,
  toneState,
  messageState,
  messagesState,
  messageCountState,
  paidState,
  isRolePlayingState,
  roleplayCharacterState,
}
