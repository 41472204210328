import React, { useEffect, useRef } from 'react'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { ReactComponent as CuteIcon } from '../images/pillow-cute.svg'
import { ReactComponent as FlirtyIcon } from '../images/pillow-flirty.svg'
import { ReactComponent as HotIcon } from '../images/pillow-hot.svg'
import { ReactComponent as TherapeuticIcon } from '../images/pillow-therapeutic.svg'
import $ from 'jquery'

import {
  isRolePlayingState,
  messageCountState,
  messagesState,
  messageState,
  roleplayCharacterState,
  toneState,
  usernameState,
  paidState,
} from '../atoms'
import { BASE_URL, incrementUserMessageCount } from '../api'

const submitMessage = (
  message,
  setMessage,
  messages,
  setMessages,
  username,
  tone,
  isRoleplaying,
  roleplayCharacter
) => {
  let streamingOpen = false
  let chatEventSource = null
  if (isRoleplaying) {
    chatEventSource = new EventSource(
      `${BASE_URL}/get_streaming_chat_response?message=${message}&username=${username}&roleplayCharacter=${roleplayCharacter}`
    )
  } else {
    chatEventSource = new EventSource(
      `${BASE_URL}/get_streaming_chat_response?message=${message}&username=${username}&tone=${tone}`
    )
  }

  let messagesCopy = messages

  chatEventSource.addEventListener('open', () => {
    if (!streamingOpen) {
      streamingOpen = true

      const newMessages = [...messages, { type: 'bot', message: '' }]
      messagesCopy = newMessages

      setMessages(newMessages)
    }
  })

  chatEventSource.addEventListener('tokens', (event) => {
    const token = event.data.toString()

    if (token) {
      const lastBotMessageIndex = findLastBotMessageIndex(messagesCopy)
      const newBotMessage = `${messagesCopy[lastBotMessageIndex].message}${token}`

      const newMessages = messagesCopy.map((item, i) => {
        if (i === lastBotMessageIndex) {
          return {
            type: item.type,
            message: newBotMessage,
          }
        } else {
          return item
        }
      })

      messagesCopy = newMessages

      setMessages(newMessages)
    }
  })

  chatEventSource.addEventListener('close', () => {
    console.log('Closing an event source')
    setMessage('')
    chatEventSource.close()
  })
}

const findLastBotMessageIndex = (messages) => {
  const i = messages
    .slice()
    .reverse()
    .findIndex((message) => message.type === 'bot')

  return messages.length - i - 1
}

function Chat() {
  const bottomRef = useRef(null)
  const [message, setMessage] = useRecoilState(messageState)
  const [messages, setMessages] = useRecoilState(messagesState)
  const setMessageCount = useSetRecoilState(messageCountState)
  const paid = useRecoilValue(paidState)

  const username = useRecoilValue(usernameState)
  const tone = useRecoilValue(toneState)
  const isRolePlaying = useRecoilValue(isRolePlayingState)
  const roleplayCharacter = useRecoilValue(roleplayCharacterState)

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  const gatherMessage = (event) => {
    setMessage(event.target.value)
  }

  const sendMessage = () => {
    const m = {
      type: 'user',
      message: message,
    }

    const newMessages = [...messages, m]

    setMessages(newMessages)

    submitMessage(
      m.message,
      setMessage,
      newMessages,
      setMessages,
      username,
      tone,
      isRolePlaying,
      roleplayCharacter
    )
    if (!paid) {
      incrementUserMessageCount(username, setMessageCount)
    }

    setMessage('')
  }

  const submitMessageClick = () => {
    $('#message-input :input').val('')
    sendMessage()
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.target.value = ''
      sendMessage()
    }
  }

  const getMessageList = () => {
    return (
      <div id='message-list' className='h-full p-4 m-4 rounded-lg flex flex-col gap-y-2'>
        {messages.map((msg, i) => {
          const messageSizing = msg.type == 'user' ? 'w-full' : 'w-full flex'
          const float = msg.type == 'user' ? 'float-right' : 'float-left'
          const colors = msg.type == 'user' ? 'bg-blue-400 text-white' : 'bg-gray-400 text-white'
          const hasIcon = msg.type !== 'user'
          const messageStyle = `${float} ${colors} rounded-xl p-4 w-fit`

          const toneToIconMap = {
            cute: CuteIcon,
            hot: HotIcon,
            therapeutic: TherapeuticIcon,
            flirty: FlirtyIcon,
          }
          const Icon = toneToIconMap[tone]
          return (
            <div key={i} className={messageSizing}>
              {hasIcon && (
                <div className='mr-2 w-16 h-1/6 md:h-16 rounded-full bg-transparent border flex items-center'>
                  <Icon className='rounded-full w-12 h-12 m-2 z-10 object-contain' />
                </div>
              )}
              <div className={messageStyle}>{msg.message}</div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div id='container' className='w-full h-5/6 md:h-screen'>
      <div id='chat' className='bg-white w-full h-4/5 md:h-5/6'>
        <div
          id='message-window'
          className='bg-white border text-black h-full flex flex-col overflow-auto'
        >
          {getMessageList()}
          <div ref={bottomRef} />
        </div>

        <div id='message-input' className='flex items-center gap-x-2 m-4'>
          <input
            type='text'
            className='bg-gray-100 resize-none focus:outline-none focus:ring h-12 focus:ring-blue-500 py-3 px-4 rounded-3xl w-full'
            placeholder='type here...'
            onChange={gatherMessage}
            onKeyDown={handleKeyDown}
            disabled={false}
          ></input>
          <button
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full'
            onClick={submitMessageClick}
          >
            send
          </button>
        </div>
      </div>
    </div>
  )
}

export default Chat
